<template>
  <div>
    <vs-radio
      class="pointer"
      @change.stop="onClick"
      color="#0B2772"
      vs-value="active"
      v-model="row.singleSelectionRate"
    >
      {{ text }}
    </vs-radio>
  </div>
</template>
<script>
export default {
  data: () => ({
    radio: 'light',
  }),
  props: {
    text: {
      type: String,
      default: ''
    },
    row: {
      type: Object,
      default: this,
    }
  },
  methods: {
    onClick() {
      this.$emit('on:radio-button-rate-check', this.row);
    }
  },
  created () {
    this.$vs.theme({
      primary:'#0B2772'
    });
  }
}
</script>
